import React from 'react';
import flow from 'lodash/flow';
import { withRouter } from 'react-router-dom';

import { withAuth } from '../../core/with-auth';
import { withUserData } from '../../core/with-user-data';
import { PortalHeaderSmall } from './portal-header-small';
import { PortalHeaderBig } from './portal-header-big';

const PortalHeaderComponent = (props) => {
  const { isLoggedIn } = props.authService;
  const { activePointOfSales } = props.userDataService;

  return isLoggedIn && activePointOfSales ? (
    <PortalHeaderBig {...props} />
  ) : (
    <PortalHeaderSmall {...props} />
  );
};

export const PortalHeader = flow([withRouter, withAuth, withUserData])(
  PortalHeaderComponent
);
