import * as actionsWithTypes from '../actions/all';

const initialState = {
  accessToken: null,
  refreshToken: null,
};

// this reducer is synced with localstorage
// it should contain only save-safe data, preferably tokens only
export function authDataReducer(state = initialState, action) {
  switch (action.type) {
    case actionsWithTypes.REFRESH_TOKEN_SUCCESS:
    case actionsWithTypes.LOGIN_SUCCESS: {
      const { accessToken, refreshToken } = action.payload;

      return {
        ...state,
        accessToken,
        refreshToken,
      };
    }

    case actionsWithTypes.LOGIN_ERROR:
    case actionsWithTypes.LOGOUT: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
