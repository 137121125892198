import { saveAs } from 'file-saver';
import {
  downloadDrafts,
  downloadOfferReport,
  downloadReservationReport,
} from './api-arval-back-office.service';
import { handleError } from './error-handler';

export const onDownloadReservationReport = async () => {
  return downloadReservationReport()
    .then((res) => {
      saveAs(
        res.data,
        `report_${new Date()
          .toJSON()
          .replace('T', '_')
          .replace(/\..*$/gi, '')
          .replace(/:/g, '-')}.xlsx`
      );
      return res;
    })
    .catch(handleError);
};

export const downloadReport = async () => {
  return downloadOfferReport()
    .then((res) => {
      const blob = new Blob([res.data], {
        type: res.headers['content-type'],
      });

      saveAs(
        blob,
        `report_${new Date()
          .toJSON()
          .replace('T', '_')
          .replace(/\..*$/gi, '')
          .replace(/:/g, '-')}.csv`
      );
      return res;
    })
    .catch(handleError);
};

export const draftsDownloader = async (userDataService) => {
  const countryId = userDataService?.activePointOfSales?.countryId;
  return downloadDrafts(countryId)
    .then((res) => {
      const blob = new Blob([res.data], {
        type: res.headers['content-type'],
      });
      saveAs(
        blob,
        `drafts_${new Date()
          .toJSON()
          .replace('T', '_')
          .replace(/\..*$/gi, '')
          .replace(/:/g, '-')}.csv`
      );
      return res;
    })
    .catch(handleError);
};
