import forOwn from 'lodash/forOwn';
import isFunction from 'lodash/isFunction';

import * as actionAndTypes from './all';

const actions = {};

forOwn(actionAndTypes, (value, key) => {
  if (isFunction(value)) {
    actions[key] = value;
  }
});

export const allActions = actions;
