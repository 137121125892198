import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { userDataReducer } from './user.reducer';
import { userPublicDataReducer } from './user-public.reducer';
import { authDataReducer } from './auth.reducer';
import { modalDataReducer } from './modal.reducer';
import { blockUnloadReducer } from './block-unload.reducer';

export const createReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    authData: authDataReducer,
    userData: userDataReducer,
    userPublicData: userPublicDataReducer,
    modalData: modalDataReducer,
    blockUnloadData: blockUnloadReducer,
  });
