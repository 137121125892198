import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import { ROLES, LOCALES, I18N_LS_KEY } from '../core/constants';
import * as actionsWithTypes from '../actions/all';

const initialState = {
  isLoggedIn: false, // can't be available as authService, we don't want to save it in localstorage
  email: null,
  username: null,
  roles: [ROLES.Anonymous],
  locale:
    find(LOCALES, { lang: localStorage[I18N_LS_KEY] })?.id ||
    LOCALES['en-GB'].id,
  activeCountry: null,
  availableCountries: [],
  availablePointOfSales: [],
};

export function userDataReducer(state = initialState, action) {
  switch (action.type) {
    case actionsWithTypes.REFRESH_TOKEN_SUCCESS:
    case actionsWithTypes.LOGIN_SUCCESS: {
      if (!action.payload.tokenData) {
        return state;
      }

      const {
        email,
        username,
        pointOfSales,
        countries,
        userId,
        locale,
        roles,
        userPriviliges,
      } = action.payload.tokenData;

      return {
        ...state,
        userId,
        email,
        username,
        userPriviliges,
        locale,
        roles,
        availableCountries: countries,
        availablePointOfSales: sortBy(pointOfSales, ['countryName', 'code']),
        isLoggedIn: true,
      };
    }

    case actionsWithTypes.LOGIN_ERROR:
    case actionsWithTypes.LOGOUT:
      return {
        ...initialState,
      };

    case actionsWithTypes.SET_USER_PRIVILIGES:
      return {
        ...state,
        userPriviliges: action.payload,
      };

    // case actionsWithTypes.SET_POINT_OF_SALES:
    //   return {
    //     ...state,
    //     activePointOfSales: _.find(state.availablePointOfSales, {pointOfSaleId: action.payload})
    //   };

    default:
      return state;
  }
}
