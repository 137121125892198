import find from 'lodash/find';
import split from 'lodash/split';
import map from 'lodash/map';
import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';

import { I18N_LS_KEY } from '../core/constants';

const mapPos = (response) => (pos) => {
  const parsedPos = { ...pos };
  const country = find(response.tokenData.countries, {
    id: Number.parseInt(pos.countryId, 10),
  });

  if (!country) {
    return null;
  }

  parsedPos.countryName = country.name;
  parsedPos.countryCode = country.code;

  return parsedPos;
};
// This variable helps to fix token refreshing issue
let refreshTokenToFix;

export const VALIDATE_ACCESS_TOKEN = 'VALIDATE_ACCESS_TOKEN';
/**
 * @param options {object} - configuration
 * @param options.accessToken {{token: string, [expiresAt]: string}} - see API auth endpoints
 * @param options.refreshToken {string} - see API auth endpoints
 * @description
 * WARNING!!!
 * If you change this method, you MUST check if /sso endpoint is still working!
 * It should validate access token with this method and redirect on success.
 */

export function validateAccessToken({ accessToken, refreshToken } = {}) {
  return (dispatch, getState, { ArvalIdentityAPI, i18n, axiosService }) => {
    let accessTokenToValidate;
    let refreshTokenToValidate;
    let response;

    if (accessToken && refreshToken) {
      accessTokenToValidate = accessToken.token;
      refreshTokenToValidate = refreshToken;
    } else {
      const { authData } = getState();
      accessTokenToValidate = authData.accessToken;
      refreshTokenToValidate = authData.refreshToken;
    }

    if (
      includes(window.location.href, '/external-login') &&
      accessTokenToValidate &&
      refreshTokenToValidate
    ) {
      window.location.href = window.location.origin;
    }

    if (!accessTokenToValidate) {
      return new Promise((resolve, reject) => {
        const err = new Error('Invalid credentials!');

        dispatch(loginError(err));
        reject(err);
      }).catch(
        axiosService.handleErrorWithPromise(
          (err) => dispatch(loginError(err)),
          false
        )
      );
    }

    return ArvalIdentityAPI.validateAccessToken({
      accessToken: accessTokenToValidate,
    })
      .then((res) => {
        const tokenData = axiosService.parseJWT(accessTokenToValidate);
        if (accessTokenToValidate && tokenData) {
          const language = split(tokenData.locale, '-')[0];

          window.localStorage[I18N_LS_KEY] = language;

          refreshTokenToFix = refreshTokenToFix || refreshTokenToValidate;
          response = {
            accessToken: accessTokenToValidate,
            refreshToken: refreshTokenToFix,
            tokenData,
            responseData: res.data,
          };

          return i18n.changeLanguage(language);
        }

        throw new Error('Invalid credentials!');
      })
      .then(() => ArvalIdentityAPI.getCurrentUserLocalizationsCountries())
      .then((res) => {
        response.tokenData.countries = res.data;
        return ArvalIdentityAPI.getCurrentUserLocalizations();
      })
      .then((res) => {
        const pointOfSales = map(res.data, mapPos(response));
        response.tokenData.pointOfSales = compact(pointOfSales);

        const { userPublicData } = getState();

        const activePointOfSales = isNil(userPublicData.activePointOfSales)
          ? pointOfSales[0]
          : userPublicData.activePointOfSales;

        return ArvalIdentityAPI.getPriviligesAsDictionary({
          countryId: activePointOfSales.countryId,
        });
      })
      .then((res) => {
        const userPriviliges = res.data;
        response.tokenData.userPriviliges = userPriviliges;
        const accessTokenFromHeaders = split(
          axiosService.axios.defaults.headers.common.Authorization,
          ' '
        )[1];
        dispatch(
          loginSuccess({
            ...response,
            accessToken: accessTokenFromHeaders,
          })
        );
        return response;
      })
      .catch(
        axiosService.handleErrorWithPromise(
          (err) => dispatch(loginError(err)),
          false
        )
      );
  };
}

export const LOGIN = 'LOGIN';
export function login({ email, password }) {
  return (dispatch, getState, { ArvalIdentityAPI, i18n, axiosService }) => {
    let response;
    return ArvalIdentityAPI.login({ email, password })
      .then((res) => {
        const { data } = res;
        const { refreshToken, accessToken } = data;
        const { token } = accessToken;
        const tokenData = axiosService.parseJWT(token);

        if (accessToken && tokenData) {
          const language = split(tokenData.locale, '-')[0];

          window.localStorage[I18N_LS_KEY] = language;

          axiosService.setAuthHeader(token);

          response = { accessToken: token, refreshToken, tokenData };

          return i18n.changeLanguage(language);
        }

        throw new Error('Invalid credentials!');
      })
      .then(() =>
        ArvalIdentityAPI.getCurrentUserLocalizationsCountries(
          response.accessToken
        )
      )
      .then((res) => {
        response.tokenData.countries = res.data;

        return ArvalIdentityAPI.getCurrentUserLocalizations();
      })
      .then((res) => {
        const pointOfSales = map(res.data, mapPos(response));

        response.tokenData.pointOfSales = compact(pointOfSales);

        dispatch(loginSuccess(response));

        return response;
      })
      .catch(
        axiosService.handleErrorWithPromise((err) => {
          axiosService.setAuthHeader();
          dispatch(loginError(err));
        }, false)
      );
  };
}

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export function refreshAccessToken(data) {
  return (dispatch, getState, { ArvalIdentityAPI, axiosService }) => {
    const {
      accessToken: { token: accessToken },
      refreshToken,
    } = data;
    if (!accessToken || !refreshToken) {
      const error = new Error('Invalid credentials!');

      dispatch(loginError(error));

      return Promise.reject(error);
    }

    return ArvalIdentityAPI.refreshAccessToken({
      accessToken,
      refreshToken,
    })
      .then((res) => {
        const { accessToken: acToken, refreshToken: refToken } = res.data;
        refreshTokenToFix = refToken;

        return dispatch(
          validateAccessToken({ accessToken: acToken, refreshToken: refToken })
        );
      })
      .catch(
        axiosService.handleErrorWithPromise(
          (err) => dispatch(loginError(err)),
          false
        )
      );
  };
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
}

export const LOGIN_ERROR = 'LOGIN_ERROR';
export function loginError(payload) {
  return {
    type: LOGIN_ERROR,
    payload,
  };
}

export function logout(payload = null) {
  return (dispatch, getState, { ArvalIdentityAPI, axiosService }) => {
    ArvalIdentityAPI.logout()
      .then((res) => {
        axiosService.setAuthHeader();
        dispatch(logoutSuccess(payload));
        return res;
      })
      .catch(() => {
        axiosService.setAuthHeader();
        dispatch(logoutSuccess(payload));
      });
  };
}

export const LOGOUT = 'LOGOUT';
export function logoutSuccess(payload) {
  return {
    type: LOGOUT,
    payload,
  };
}

export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export function refreshTokenSuccess(payload) {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload,
  };
}

export const TOGGLE_BLOCK_UNLOAD = 'BLOCK_UNLOAD';
export function toggleBlockUnload(payload = true) {
  return {
    type: TOGGLE_BLOCK_UNLOAD,
    payload,
  };
}

export const PROMPT_TOGGLE_BLOCK_UNLOAD = 'PROMPT_TOGGLE_BLOCK_UNLOAD';
export function promptToggleBlockUnload(payload = true) {
  return {
    type: PROMPT_TOGGLE_BLOCK_UNLOAD,
    payload,
  };
}

export const SET_USER_PRIVILIGES = 'SET_USER_PRIVILIGES';
export function setUserPriviliges(pointOfSaleId) {
  return (dispatch, getState, { ArvalIdentityAPI, axiosService }) => {
    const state = getState();
    const { availablePointOfSales } = state.userData;
    const nextPointOfSale =
      find(availablePointOfSales, { pointOfSaleId }) || null;

    return ArvalIdentityAPI.getPriviligesAsDictionary({
      countryId: nextPointOfSale.countryId,
    })
      .then((res) => {
        const userPriviliges = res.data;
        return dispatch({
          type: SET_USER_PRIVILIGES,
          payload: userPriviliges,
        });
      })
      .catch(
        axiosService.handleErrorWithPromise(
          (err) => dispatch(loginError(err)),
          false
        )
      );
  };
}
// set only save in global storage
// change buisness logic to prepare data for dispatch
export const SET_POINT_OF_SALES = 'SET_POINT_OF_SALES';
export function setPointOfSales(pointOfSaleId) {
  return (dispatch, getState) => {
    const state = getState();
    const { availablePointOfSales } = state.userData;
    const { isBlockedUnload } = state.blockUnloadData;
    const payload = find(availablePointOfSales, { pointOfSaleId }) || null;

    if (isBlockedUnload === true) {
      dispatch({
        type: PROMPT_TOGGLE_BLOCK_UNLOAD,
        payload: true,
      });
    }

    return dispatch({
      type: SET_POINT_OF_SALES,
      payload,
    });
  };
}

export const FETCH_POINT_OF_SALES_AND_COUNTRIES =
  'FETCH_POINT_OF_SALES_AND_COUNTRIES';
export function fetchPointOfSalesAndCountries() {
  return (dispatch, getState, { ArvalIdentityAPI, axiosService }) => {
    return Promise.all([
      ArvalIdentityAPI.getCountries,
      ArvalIdentityAPI.getPointOfSales,
    ])
      .then((values) => {
        const [countriesRes, pointOfSalesRes] = values;
        console.warn([countriesRes, pointOfSalesRes]);
        return values;
      })
      .catch(
        axiosService.handleErrorWithPromise(
          (err) => dispatch(loginError(err)),
          false
        )
      );
  };
}
