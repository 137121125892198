import * as actionsWithTypes from '../actions/all';

const initialState = {
  modalQueue: [],
  currentModal: null,
};

export function modalDataReducer(state = initialState, action) {
  switch (action.type) {
    case actionsWithTypes.REFRESH_TOKEN_SUCCESS:
    case actionsWithTypes.LOGIN_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
}
