import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { ToastContainer } from 'react-toastify'; // https://github.com/fkhadra/react-toastify
import cx from 'classnames';
import { AppSpinner } from './components/shared/app-spinner';
import { AppRouting } from './app-routing';
import { store, history } from './store';
import { path } from './core/utils';
import { isUat } from './core/constants';
import { PortalHeader } from './components/shared/portal-header';

export const App = () => {
  return (
    <Suspense fallback={<AppSpinner absolute />}>
      <Provider store={store}>
        <ConnectedRouter history={history} basename={path('')}>
          <PortalHeader />
          <div className={cx({ 'app--style-uat': isUat })}>
            <AppRouting />
          </div>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange={false}
          />
        </ConnectedRouter>
      </Provider>
    </Suspense>
  );
};
