import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import flow from 'lodash/flow';
import includes from 'lodash/includes';
import some from 'lodash/some';

import { withAuth } from './with-auth';
import { withUserData } from './with-user-data';
import { AppSpinner } from '../components/shared/app-spinner';

export const withACL = (WrappedComponent) => {
  class WithAuth extends Component {
    static displayName = `WithACL(${
      WrappedComponent?.displayName || WrappedComponent.name
    })`;

    state = {
      isLoading: true,
      isAccessDenied: true,
    };

    componentDidMount = () => {
      const { userDataService, route } = this.props;
      const { roles } = userDataService;

      this.setState({
        isAccessDenied: !some(roles, (role) =>
          includes(route.allowRoles, role)
        ),
        isLoading: false,
      });
    };

    render = () => {
      const { isLoading, isAccessDenied } = this.state;

      if (isLoading) {
        return <AppSpinner />;
      }

      if (isAccessDenied) {
        return <Redirect to='/no-access' />;
      }

      return <WrappedComponent {...this.props} />;
    };
  }

  return flow([withAuth, withUserData, withRouter])(WithAuth);
};
