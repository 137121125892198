import React from 'react';
import { withRouter } from 'react-router-dom';
import flow from 'lodash/flow';
import includes from 'lodash/includes';

import { renderRoutes } from 'react-router-config';
import { withAuth } from './core/with-auth';
import { aclRouting } from './app-acl';

class AppRoutingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isTokenChecked: false,
    };
  }

  componentDidMount() {
    this.props.authService
      .validateAccessToken()
      .then((res) => {
        this.setState({ isTokenChecked: true });

        return res;
      })
      .catch(() => {
        const { history } = this.props;
        if (
          !includes(
            [
              '/external-login',
              '/sso',
              '/no-access',
              '/reset-password',
              '/forgot-password',
            ],
            history.location.pathname
          )
        ) {
          this.props.history.push('/login');
        }
        this.setState({ isTokenChecked: true });
      });
  }

  render() {
    if (!this.state.isTokenChecked) {
      return null;
    }

    return renderRoutes(aclRouting);
  }
}

export const AppRouting = flow([withAuth, withRouter])(AppRoutingContainer);
