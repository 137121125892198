import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import filter from 'lodash/filter';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import noop from 'lodash/noop';

export const FormGroupSearchSelect = ({
  id,
  options,
  selectedVal,
  handleChange,
  className = '',
  placeholder = '',
}) => {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const inputRef = useRef(null);
  const optionsContainerRef = useRef(null);
  const buttonRef = useRef(null);

  const { t } = useTranslation();

  const toggle = (e) => {
    if (e && e.target === inputRef.current) {
      setIsOpen(true);
    }
    if (e && e.target !== inputRef.current && e.target !== buttonRef.current) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', toggle);
    return () => document.removeEventListener('click', toggle);
  }, []);

  useEffect(() => {
    if (highlightedIndex !== -1 && optionsContainerRef.current) {
      const highlightedOption = optionsContainerRef.current.querySelector(
        `.form-group--searchselect-option:nth-child(${highlightedIndex + 1})`
      );

      if (highlightedOption) {
        highlightedOption.scrollIntoView({
          block: 'nearest',
        });
      }
    }
  }, [highlightedIndex]);

  const filterOpts = (opts) => {
    return filter(opts, (option) =>
      includes(toLower(option.name), toLower(query))
    );
  };

  const selectOption = (option) => {
    setQuery('');
    handleChange(option);
    setIsOpen(!isOpen);
    setHighlightedIndex(-1);

    if (optionsContainerRef.current) {
      optionsContainerRef.current.scrollTop = 0;
    }
  };

  const handleArrowKeyPress = (e) => {
    e.preventDefault();
    const optionsCount = filterOpts(options).length;

    if (optionsCount > 0) {
      let newIndex = highlightedIndex + (e.key === 'ArrowDown' ? 1 : -1);
      if (newIndex < 0) {
        newIndex = optionsCount - 1;
      } else if (newIndex >= optionsCount) {
        newIndex = 0;
      }
      setHighlightedIndex(newIndex);
    }
  };

  const handleEnterKeyPress = (e) => {
    e.preventDefault();
    if (isOpen) {
      if (highlightedIndex !== -1) {
        selectOption(filterOpts(options)[highlightedIndex]);
      }
    } else {
      setIsOpen(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      handleArrowKeyPress(e);
    } else if (e.key === 'Enter') {
      handleEnterKeyPress(e);
    }
  };

  const getDisplayValue = () => {
    if (isOpen && !query) return '';
    if (query) return query;
    if (selectedVal) return selectedVal;

    return '';
  };

  return (
    <div className={`form-group--searchselect ${className}`}>
      <input
        id={id}
        name={id}
        className='form-control'
        ref={inputRef}
        type='text'
        value={getDisplayValue()}
        onChange={(e) => {
          setQuery(e.target.value);
          handleChange(null);
        }}
        onKeyDown={handleKeyDown}
        onClick={toggle}
        placeholder={placeholder}
        autoComplete='off'
      />
      <div
        className={`form-group--searchselect-options ${isOpen ? 'open' : ''}`}
        ref={optionsContainerRef}
      >
        {filterOpts(options).length > 0 ? (
          map(filterOpts(options), (option, i) => {
            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <div
                key={option.value}
                className={`form-group--searchselect-option ${
                  i === highlightedIndex ? 'highlighted' : ''
                }`}
                onClick={() => selectOption(option)}
                onKeyDown={noop}
                role='search'
              >
                {option.name}
              </div>
            );
          })
        ) : (
          <div className='form-group--searchselect-no-results'>
            {t('errorNoResults')}
          </div>
        )}
      </div>
      <button
        ref={buttonRef}
        type='button'
        className={
          isOpen
            ? 'form-group--searchselect-arrow-opened'
            : 'form-group--searchselect-arrow-closed'
        }
        onClick={() => setIsOpen(!isOpen)}
      />
    </div>
  );
};
