import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import forEach from 'lodash/forEach';

import { FormGroupSearchSelect } from '../forms/fields/form-group-searchselect-field';

export const HeaderPosChange = ({
  availablePointOfSales,
  onChange,
  activePointOfSales,
}) => {
  const { t } = useTranslation();
  const [pos, setPos] = useState(
    activePointOfSales && activePointOfSales.pointOfSaleId
  );
  const [value, setValue] = useState(
    `${activePointOfSales.countryName}, ${activePointOfSales.pointOfSaleName} [${activePointOfSales.pointOfSaleCode}]`
  );

  if (!activePointOfSales) {
    return null;
  }

  const onPosChange = (option) => {
    if (option === null) return;

    if (activePointOfSales.pointOfSaleId !== option.value) {
      setValue(option.name);
      onChange(option.value);
      setPos(option.value);
    }
  };

  if (activePointOfSales.pointOfSaleId !== pos) {
    setPos(activePointOfSales.pointOfSaleId);
  }

  let posOptions = [];
  forEach(availablePointOfSales, (pointOfSale) => {
    posOptions = [
      ...posOptions,
      {
        name: `${pointOfSale.countryName}, ${pointOfSale.pointOfSaleName} [${pointOfSale.pointOfSaleCode}]`,
        value: pointOfSale.pointOfSaleId,
      },
    ];
  });

  return (
    <form className='form-compact'>
      <div className='form-group col-xs-8 l-no-padding'>
        <label className='label label--inline' htmlFor='posContext'>
          {t('header_active_dealer')}
        </label>
        <div className='select-content'>
          <FormGroupSearchSelect
            id='posContext'
            options={posOptions}
            selectedVal={value}
            handleChange={(val) => onPosChange(val)}
          />
        </div>
      </div>
    </form>
  );
};
