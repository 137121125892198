import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import noop from 'lodash/noop';

import { allActions } from '../actions';

export const AUTH_PROP_TYPES = {};

export const withAuth = (WrappedComponent) => {
  class WithAuth extends Component {
    static displayName = `WithAuth(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;

    static propTypes = AUTH_PROP_TYPES;

    componentDidMount = noop;

    render() {
      const { actions, userData, ...rest } = this.props;
      const { isLoggedIn } = userData;
      const authService = {
        isLoggedIn,
        validateAccessToken: actions.validateAccessToken,
        login: actions.login,
        logout: actions.logout,
        refreshAccessToken: actions.refreshAccessToken,
      };

      return <WrappedComponent authService={authService} {...rest} />;
    }
  }

  const mapStateToProps = ({ userData, authData }) => ({ userData, authData });

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        ...allActions,
      },
      dispatch
    ),
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithAuth);
};
