import React, { useState, useEffect, useRef } from 'react';
import map from 'lodash/map';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import flow from 'lodash/flow';
import { withUserData } from '../../core/with-user-data';

const RenderHeaderNavLink = ({ url, name, icon, onClick, userDataService }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  let checkActiveRoot = null;
  if (url === '/') {
    checkActiveRoot = (match, location) => {
      if (!location) return false;
      const { pathname } = location;
      return pathname === '/';
    };
  }

  const onButtonClick = () => {
    setLoading(true);
    onClick({ userDataService })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.info(e);
      });
  };

  const title = t(name);
  const downloadingTitle = isLoading ? t('common_downloading') : t(name);

  return (
    <li className='menu_li'>
      {icon ? (
        <NavLink
          isActive={checkActiveRoot}
          className='menu_a'
          to={url}
          data-ico-before={icon}
          title={title}
          onClick={onClick}
          activeClassName='is-active'
        />
      ) : url ? (
        <NavLink
          isActive={checkActiveRoot}
          className='menu_a'
          to={url}
          title={title}
          onClick={onClick}
          activeClassName='is-active'
        >
          <span className='menu_dropdown-text'>{title}</span>
        </NavLink>
      ) : (
        <button
          className={cx('menu_a', { 'is-downloading': isLoading })}
          title={title}
          disabled={isLoading}
          onClick={onButtonClick}
        >
          <span className='menu_dropdown-text'>{downloadingTitle}</span>
        </button>
      )}
    </li>
  );
};

const RenderHeaderRouteList = ({
  name,
  items,
  expanded,
  onClick,
  onClose,
  userDataService,
}) => {
  return (
    <li className='menu_li'>
      <button
        onClick={onClick}
        type='button'
        className={cx('menu_a', 'menu_dropdown', {
          'is-active': expanded,
        })}
      >
        <span className='menu_dropdown-text' title={name}>
          {name}
        </span>
      </button>
      <div
        className={cx('menu_expand-container', {
          'is-active': expanded,
        })}
      >
        <div className='menu_sub-nav'>
          <ul className='menu_ul'>
            {map(items, (item) => (
              <RenderHeaderNavLink
                userDataService={userDataService}
                key={item.name}
                onClick={onClose}
                {...item}
              />
            ))}
          </ul>
        </div>
      </div>
    </li>
  );
};

const HeaderNavComponent = ({ routeLinks, userDataService }) => {
  const { t } = useTranslation();
  const [expandedIndex, setExpand] = useState(null);
  const useOutsideAlerter = (ref) => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpand(null);
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  if (!routeLinks || routeLinks.length === 0) {
    return null;
  }

  const handleClose = () => {
    setExpand(null);
  };

  const handleResize = () => {
    handleClose();
    window.removeEventListener('resize', handleResize);
  };

  const changeExpandedLink = (index) => () => {
    if ((expandedIndex === null || expandedIndex !== index) && index !== null) {
      setExpand(index);
      window.addEventListener('resize', handleResize);
    } else {
      setExpand(null);
      window.removeEventListener('resize', handleResize);
    }
  };

  return (
    <ul className='menu_ul' ref={wrapperRef}>
      {map(routeLinks, (item, index) => {
        if (item.hasOwnProperty('items')) {
          return (
            <RenderHeaderRouteList
              userDataService={userDataService}
              key={`route-list_${index}`}
              name={t(item.name)}
              items={item.items}
              expanded={index === expandedIndex}
              onClose={handleClose}
              onClick={changeExpandedLink(index)}
            />
          );
        }
        return (
          <RenderHeaderNavLink
            userDataService={userDataService}
            key={`route-list_${index}`}
            onClick={changeExpandedLink(index)}
            {...item}
          />
        );
      })}
    </ul>
  );
};

export const HeaderNav = flow([withUserData])(HeaderNavComponent);
