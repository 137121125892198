import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import persistState from 'redux-localstorage';

import { createReducers } from './reducers';
import * as axiosService from './core/axios.service';
// import * as ArvalBackOfficeAPI from './core/api-arval-back-office.service';
import * as ArvalIdentityAPI from './core/api-arval-identity.service';
// import * as MotoTradeAPI from './core/api-moto-trade.service';
import { path } from './core/utils';
import { i18n } from '../i18n';

export const history = createBrowserHistory({
  basename: path('/'),
});

const serialize = (subset) => {
  if (process.env.NODE_ENV === 'production') {
    const base64String = btoa(encodeURIComponent(JSON.stringify(subset)));
    return base64String;
  }

  return JSON.stringify(subset);
};

const deserialize = (serializedData) => {
  if (process.env.NODE_ENV === 'production') {
    const decodedString = decodeURIComponent(
      atob(serializedData || btoa('{}'))
    );
    return JSON.parse(decodedString);
  }

  return JSON.parse(serializedData);
};

export const store = createStore(
  createReducers(history),
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware.withExtraArgument({
        // ArvalBackOfficeAPI,
        ArvalIdentityAPI,
        // MotoTradeAPI,
        history,
        i18n,
        axiosService,
      })
    ),
    persistState(['authData', 'userPublicData'], {
      key: 'arval-om-v1', // warning! Changing this will LOGOUT EVERY user!
      serialize,
      deserialize,
    }),
    /* eslint-disable no-underscore-dangle */
    ...(window.__REDUX_DEVTOOLS_EXTENSION__
      ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
      : [])
    /* eslint-enable no-underscore-dangle */
  )
);

axiosService.init({ store, history, i18n });
