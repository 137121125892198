import React from 'react';
import cx from 'classnames';

export class AppSpinner extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: false,
    };

    this.timeoutHandler = null;
  }

  componentDidMount() {
    // we don't want to blink with spinner if eg. the request was quick
    this.timeoutHandler = setTimeout(() => {
      this.setState({ showSpinner: true });
    }, 0);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutHandler);
  }

  render() {
    const { children, size, className } = this.props;
    const { showSpinner } = this.state;

    if (showSpinner) {
      return (
        <>
          {children || null}
          <div className='l-preloader'>
            <div
              className={cx('loader', className, {
                'loader--small': size === 'sm',
                'loader--medium': size === 'md',
              })}
            />
          </div>
        </>
      );
    }

    return children || null;
  }
}
