import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { HeaderNav } from './header-nav';
import { HeaderPosChange } from './header-pos-change';
import { path } from '../../core/utils';
import { getFilteredACLMenu } from '../../app-acl';

export class PortalHeaderBig extends Component {
  onPosChange = (posId) => {
    this.props.userDataService.setPointOfSales(Number.parseInt(posId, 10));
    this.props.userDataService.setUserPriviliges(Number.parseInt(posId, 10));
  };

  render() {
    const { history, userDataService } = this.props;
    const { username, availablePointOfSales, activePointOfSales, roles } =
      userDataService;

    const routeLinks = getFilteredACLMenu(roles);

    if (!availablePointOfSales) {
      return null;
    }

    return (
      <header className='main-header popup-hidden l-min-width'>
        <nav className='top-nav'>
          <div className='container'>
            <div className='top-nav_menu row'>
              <HeaderPosChange
                availablePointOfSales={availablePointOfSales}
                activePointOfSales={activePointOfSales}
                onChange={this.onPosChange}
                history={history}
              />

              <ul className='l-list-nostyles text-right top-nav_ul col-xs-4 text-right l-no-padding'>
                <li className='top-nav_li'>
                  Hello,{' '}
                  <Link to='/account/my-profile' className='text-primary'>
                    {username}
                  </Link>
                  !
                </li>
                <li className='top-nav_li'>
                  <Link to='/login'>Logout</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className='main-container'>
          <div className='container header-content l-min-width'>
            <div className='row'>
              <div className='new-logo-body col-xs-12 text-right'>
                <div className='new-logo'>
                  <Link to='/' title='Arval'>
                    <img
                      className=''
                      src={path('/assets/images/logo-arval.png')}
                      alt='Arval'
                    />
                  </Link>
                </div>
              </div>

              <div id='standaloneMenu' className='col-xs-12 menu'>
                <HeaderNav routeLinks={routeLinks} />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
