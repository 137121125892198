import { useState } from 'react';
import { saveAs } from 'file-saver';

export const path = (pathname, withOrigin = false) =>
  `${withOrigin ? window.location.origin : ''}${
    process.env.PUBLIC_URL
  }${pathname}`;

export const randomId = () => Math.random().toString(32).slice(2);

export const arrayMove = (array, oldIndex, newIndex) => {
  const arr = [...array];
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    // eslint-disable-next-line no-plusplus
    while (k--) {
      arr.push();
    }
  }

  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

  return arr;
};

// create your forceUpdate hook
export function useForceUpdate() {
  const setValue = useState(0)[1]; // integer state

  return () => setValue((value) => value + 1); // update the state to force render
}

export const saveCsv = (res) => {
  const blob = new Blob([res.data], {
    type: res.headers['content-type'],
  });

  saveAs(
    blob,
    `report_${new Date()
      .toJSON()
      .replace('T', '_')
      .replace(/\..*$/gi, '')
      .replace(/:/g, '-')}.csv`
  );

  return res;
};
