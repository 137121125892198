import find from 'lodash/find';

import * as actionsWithTypes from '../actions/all';
import { LOCALES, I18N_LS_KEY } from '../core/constants';

const initialState = {
  locale:
    find(LOCALES, { lang: localStorage[I18N_LS_KEY] })?.id ||
    LOCALES['en-GB'].id,
  activePointOfSales: null,
};

// this reducer is synced with localstorage
// it should contain only save-safe data, preferably tokens only
export function userPublicDataReducer(state = initialState, action) {
  switch (action.type) {
    case actionsWithTypes.REFRESH_TOKEN_SUCCESS:
    case actionsWithTypes.LOGIN_SUCCESS: {
      if (!action.payload.tokenData) {
        return state;
      }

      const { pointOfSales, locale } = action.payload.tokenData;

      let activePointOfSales =
        state.activePointOfSales || initialState.activePointOfSales;

      if (
        !activePointOfSales ||
        !find(
          pointOfSales,
          (pos) => pos.pointOfSaleId === activePointOfSales.pointOfSaleId
        )
      ) {
        activePointOfSales = pointOfSales[0];
      }

      return {
        ...state,
        locale,
        activePointOfSales,
      };
    }

    case actionsWithTypes.LOGIN_ERROR:
    case actionsWithTypes.LOGOUT:
      return {
        ...initialState,
      };

    case actionsWithTypes.SET_POINT_OF_SALES:
      return {
        ...state,
        activePointOfSales: action.payload,
      };

    default:
      return state;
  }
}
