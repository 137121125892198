import isFunction from 'lodash/isFunction';

import {
  axios,
  axiosWithCache,
  arvalBackOfficeUrl,
  arvalReportManagerUrl,
  handleErrorWithPromise,
  arvalIdentityUrl,
} from './axios.service';
import { DEFAULT_PAGE_SIZE } from './constants';

const { CancelToken } = axios;

// https://confluence.makolab.net/display/CIDAU/Offer+Manager+%3E+Swagger+Arval+Backoffice+Api
// responsible for announcements

export const v = ''; // version

// DRAFTS
export const getDraftSource = CancelToken.source();
export const getDrafts = ({
  orderBy,
  order,
  pageNumber,
  pageSize,
  countryCode,
  pointOfSaleCode,
  make,
  model,
  registrationNumber,
  vin,
  version,
}) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Drafts'),
      params: {
        orderBy: orderBy || null,
        orderDirection: order || 'asc',
        pageNumber: Number.parseInt(pageNumber, 10) || 1,
        pageSize: Number.parseInt(pageSize, 10) || DEFAULT_PAGE_SIZE,
        countryCode,
        pointOfSaleCode,
        make: make || null,
        model: model || null,
        registrationNumber: registrationNumber || null,
        vin: vin || null,
        version: version || null,
        v,
      },
      cancelToken: getDraftSource.token,
    })
    .catch(handleErrorWithPromise());

export const getGhostDraftsSource = CancelToken.source();
export const getGhostDrafts = ({
  orderBy,
  order,
  pageNumber,
  pageSize,
  countryCode,
  pointOfSaleCode,
  make,
  model,
  registrationNumber,
  vin,
  version,
}) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Drafts/Ghosts'),
      params: {
        orderBy: orderBy || null,
        orderDirection: order || 'asc',
        pageNumber: Number.parseInt(pageNumber, 10) || 1,
        pageSize: Number.parseInt(pageSize, 10) || DEFAULT_PAGE_SIZE,
        countryCode,
        pointOfSaleCode,
        make: make || null,
        model: model || null,
        registrationNumber: registrationNumber || null,
        vin: vin || null,
        version: version || null,
        v,
      },
      cancelToken: getGhostDraftsSource.token,
    })
    .catch(handleErrorWithPromise());

export const getLogsMotorTradeSource = CancelToken.source();
export const getLogsMotorTrade = () =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Reports/MotorTrade'),
      params: {
        v,
      },
      cancelToken: getLogsMotorTradeSource.token,
    })
    .catch(handleErrorWithPromise());

export const getLogsMarketingSource = CancelToken.source();
export const getLogsMarketing = (path, portalIds) =>
  axios
    .request({
      method: 'GET',
      url: arvalReportManagerUrl(path),
      params: {
        v,
        portalIds,
      },
      cancelToken: getLogsMarketingSource.token,
    })
    .catch(handleErrorWithPromise());

export const downloadOfferReportSource = CancelToken.source();
export const downloadOfferReport = () =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Reports/Announcements/File'),
      params: {
        v,
      },
      cancelToken: downloadOfferReportSource.token,
    })
    .catch(handleErrorWithPromise());

export const downloadReservationReportSource = CancelToken.source();
export const downloadReservationReport = () =>
  axios
    .request({
      method: 'GET',
      url: arvalReportManagerUrl('/reservation/by/countryGroup'),
      params: {
        v,
      },
      responseType: 'blob',
      cancelToken: downloadReservationReportSource.token,
    })
    .catch(handleErrorWithPromise());

export const downloadDraftsSource = CancelToken.source();
export const downloadDrafts = (countryId) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Drafts/${countryId}/Drafts`),
      params: {
        v,
      },
      cancelToken: downloadDraftsSource.token,
    })
    .catch(handleErrorWithPromise());

export const downloadFileSource = CancelToken.source();
export const downloadFile = (url, api = 'backoffice') =>
  axios
    .request({
      method: 'GET',
      url: api === 'identity' ? arvalIdentityUrl(url) : arvalBackOfficeUrl(url),
      params: {
        v,
      },
      cancelToken: downloadFileSource.token,
    })
    .catch(handleErrorWithPromise());

export const uploadZipSource = CancelToken.source();
export const uploadZip = ({ formData, onProgress }) =>
  axios
    .request({
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: arvalBackOfficeUrl('/Sessions'),
      params: {
        v,
      },
      onUploadProgress: (progressEvent) => {
        if (isFunction(onProgress)) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          onProgress(percentCompleted);
        }
      },
      data: formData,
      cancelToken: uploadZipSource.token,
    })
    .catch(handleErrorWithPromise());

export const uploadOfferZipSource = CancelToken.source();
export const uploadOfferZip = ({ formData, onProgress }) =>
  axios
    .request({
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: arvalBackOfficeUrl('/Sessions/CreateSession'),
      params: {
        v,
      },
      onUploadProgress: (progressEvent) => {
        if (isFunction(onProgress)) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          onProgress(percentCompleted);
        }
      },
      data: formData,
      cancelToken: uploadOfferZipSource.token,
    })
    .catch(handleErrorWithPromise());

export const createOfferSource = CancelToken.source();
export const createOffer = ({ draftId, countryCode, posCode }) =>
  axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl('/Announcements'),
      params: {
        v,
      },
      data: {
        id: draftId,
        countryCode,
        pointOfSaleCode: posCode || undefined,
      },
      cancelToken: createOfferSource.token,
    })
    .catch(handleErrorWithPromise());

export const createOfferFromFileSource = CancelToken.source();
export const createOfferFromFile = ({ data }) =>
  axios
    .request({
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: arvalBackOfficeUrl('/Announcements/CreateManuallyFromXlsx'),
      params: {
        v,
      },
      data,
      cancelToken: createOfferFromFileSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferExcelFileSource = CancelToken.source();
export const getOfferExcelFile = ({
  clearCacheEntry = false,
  countryId,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Announcements/CreateManuallyFromXlsx/File'),
      params: {
        v,
        countryId,
      },
      responseType: 'blob',
      cancelToken: getOfferExcelFileSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getOfferExcelFileInfoSource = CancelToken.source();
export const getOfferExcelFileInfo = ({
  clearCacheEntry = false,
  countryId,
  pageNumber = 1,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Announcements/CreateManuallyFromXlsx/Info'),
      params: {
        v,
        countryId,
        pageNumber,
      },
      cancelToken: getOfferExcelFileInfoSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getOffersSource = CancelToken.source();
export const getOffers = ({
  orderBy,
  order,
  pageNumber,
  pageSize,
  pointOfSaleId,
  make,
  label,
  model,
  offerId,
  registrationNumber,
  vin,
  version,
  deletionReason,
  marketingLabel,
  reservationLabel,
  status,
} = {}) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Announcements'),
      params: {
        pageNumber: Number.parseInt(pageNumber, 10) || 1,
        pageSize: Number.parseInt(pageSize, 10) || DEFAULT_PAGE_SIZE,
        orderBy: orderBy || null,
        orderDirection: order || 'asc',
        make: make || null,
        model: model || null,
        label: label || null,
        offerId: offerId || null,
        registrationNumber: registrationNumber || null,
        vin: vin || null,
        version: version || null,
        deletionReason: deletionReason || null,
        marketingLabel: marketingLabel || null,
        reservationLabel: reservationLabel || null,
        status: status || null,
        pointOfSaleId,
        v,
      },
      cancelToken: getOffersSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOffersAllPosSource = CancelToken.source();
export const getOffersAllPos = ({ offerId, registrationNumber, vin } = {}) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Announcements/AnnouncementsAndDrafts'),
      params: {
        offerId: offerId || null,
        registrationNumber: registrationNumber || null,
        vin: vin || null,
        v,
      },
      cancelToken: getOffersAllPosSource.token,
    })
    .catch(handleErrorWithPromise());

export const deleteOfferSource = CancelToken.source();
export const deleteOffer = ({ offerId, deletionReason }) =>
  axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}`),
      params: {
        v,
      },
      data: {
        deletionReason,
      },
      cancelToken: deleteOfferSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferConfigurationSource = CancelToken.source();
export const getOfferConfiguration = ({ offerId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Configuration`),
      params: {
        v,
      },
      cancelToken: getOfferConfigurationSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferVehicleDataSource = CancelToken.source();
export const getOfferVehicleData = ({ offerId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/VehicleData`),
      params: {
        v,
      },
      cancelToken: getOfferVehicleDataSource.token,
    })
    .catch(handleErrorWithPromise());

export const saveOfferVehicleDataSource = CancelToken.source();
export const saveOfferVehicleData = ({ offerId, formData }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/VehicleData`),
      params: {
        v,
      },
      data: formData,
      cancelToken: saveOfferVehicleDataSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOffersTableSettingsSource = CancelToken.source();
export const getOffersTableSettings = () =>
  axios
    .request({
      method: 'GET',
      // url: '/assets/moc/offers-table-settings.json',
      url: arvalBackOfficeUrl(`/SalesPersons/TableSettings`),
      params: {
        v,
      },
      cancelToken: getOffersTableSettingsSource.token,
    })
    .catch(handleErrorWithPromise());

export const saveOffersTableSettingsSource = CancelToken.source();
export const saveOffersTableSettings = (tableSettings) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/SalesPersons/TableSettings`),
      params: {
        v,
      },
      data: {
        salesPersonTableSettingList: tableSettings,
      },
      cancelToken: saveOffersTableSettingsSource.token,
    })
    .catch(handleErrorWithPromise());

export const refreshCarPassReportUrlSource = CancelToken.source();
export const refreshCarPassReportUrl = ({ offerId }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Reports/CarPass`),
      params: {
        v,
      },
      data: {},
      cancelToken: refreshCarPassReportUrlSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferEquipmentSource = CancelToken.source();
export const getOfferEquipment = ({ offerId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Equipment`),
      params: {
        v,
      },
      cancelToken: getOfferEquipmentSource.token,
    })
    .catch(handleErrorWithPromise());

export const saveOfferEquipmentSource = CancelToken.source();
export const saveOfferEquipment = ({ offerId, formData }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Equipment`),
      params: {
        v,
      },
      data: formData,
      cancelToken: saveOfferEquipmentSource.token,
    })
    .catch(handleErrorWithPromise());

export const createOfferEquipmentSource = CancelToken.source();
export const createOfferEquipment = ({ offerId, formData }) =>
  axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Equipment`),
      params: {
        v,
      },
      data: formData,
      cancelToken: createOfferEquipmentSource.token,
    })
    .catch(handleErrorWithPromise());

export const deleteOfferEquipmentSource = CancelToken.source();
export const deleteOfferEquipment = ({ offerId, equipmentId }) =>
  axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(
        `/Announcements/${offerId}/Equipment/${equipmentId}`
      ),
      params: {
        v,
      },
      cancelToken: deleteOfferEquipmentSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferFinancingSource = CancelToken.source();
export const getOfferFinancing = ({ offerId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Financing`),
      params: {
        v,
      },
      cancelToken: getOfferFinancingSource.token,
    })
    .catch(handleErrorWithPromise());

export const resetPriceSource = CancelToken.source();
export const resetPrice = ({ offerId }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Financing/ResetPrice`),
      params: {
        v,
      },
      cancelToken: resetPriceSource.token,
    })
    .catch(handleErrorWithPromise());

export const resetPricesSource = CancelToken.source();
export const resetPrices = ({ regNums }) =>
  axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(
        `/Announcements/Financing/ResetPricesByRegistrationNumbers`
      ),
      headers: {
        'Content-Type': 'text/json',
      },
      params: {
        v,
      },
      data: JSON.stringify(regNums),
      cancelToken: resetPriceSource.token,
    })
    .catch(handleErrorWithPromise());

export const saveOfferFinancingSource = CancelToken.source();
export const saveOfferFinancing = ({ offerId, formData }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Financing`),
      params: {
        v,
      },
      data: formData,
      cancelToken: saveOfferFinancingSource.token,
    })
    .catch(handleErrorWithPromise());
export const getOfferPortalsSource = CancelToken.source();
export const getOfferPortals = ({ offerId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/FieldsValues`),
      params: {
        v,
      },
      cancelToken: getOfferPortalsSource.token,
    })
    .catch(handleErrorWithPromise());

export const getModelsSource = CancelToken.source();
export const getModels = (portalId, makeName) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(
        `/ExternalApi/GetModels?PortalId=${portalId}&MakeName=${makeName}`
      ),
      params: {
        v,
      },
      cancelToken: getModelsSource.token,
    })
    .catch(handleErrorWithPromise());

export const getVersionsSource = CancelToken.source();
export const getVersions = (portalId, makeName, modelName) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(
        `/ExternalApi/GetVersions?PortalId=${portalId}&MakeName=${makeName}&ModelName=${modelName}`
      ),
      params: {
        v,
      },
      cancelToken: getVersionsSource.token,
    })
    .catch(handleErrorWithPromise());

export const getGenerationsSource = CancelToken.source();
export const getGenerations = (portalId, makeName, modelName) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(
        `/ExternalApi/GetGenerations?PortalId=${portalId}&MakeName=${makeName}&ModelName=${modelName}`
      ),
      params: {
        v,
      },
      cancelToken: getGenerationsSource.token,
    })
    .catch(handleErrorWithPromise());

export const saveOfferPortalsSource = CancelToken.source();
export const saveOfferPortals = ({ offerId, formData }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/FieldsValues`),
      params: {
        v,
      },
      data: formData,
      cancelToken: saveOfferPortalsSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferDamagesSource = CancelToken.source();
export const getOfferDamages = ({ offerId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Damages`),
      params: {
        v,
      },
      cancelToken: getOfferDamagesSource.token,
    })
    .catch(handleErrorWithPromise());

export const saveOfferDamagesSource = CancelToken.source();
export const saveOfferDamages = ({ offerId, formData }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Damages`),
      params: {
        v,
      },
      data: formData,
      cancelToken: saveOfferDamagesSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferMaintenanceSource = CancelToken.source();
export const getOfferMaintenance = ({ offerId, pageNumber, pageSize }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/MaintenanceHistory`),
      params: {
        v,
        PageNumber: Number.parseInt(pageNumber, 10) || 1,
        PageSize: Number.parseInt(pageSize, 10) || DEFAULT_PAGE_SIZE,
      },
      cancelToken: getOfferMaintenanceSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferMaintenanceVisibilitySource = CancelToken.source();
export const getOfferMaintenanceVisibility = ({ offerId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(
        `/Announcements/${offerId}/MaintenanceHistory/Visibility`
      ),
      params: {
        v,
      },
      cancelToken: getOfferMaintenanceVisibilitySource.token,
    })
    .catch(handleErrorWithPromise());

export const saveOfferMaintenanceVisibilitySource = CancelToken.source();
export const saveOfferMaintenanceVisibility = ({ offerId, visible }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(
        `/Announcements/${offerId}/MaintenanceHistory/Visibility`
      ),
      params: {
        v,
      },
      data: {
        visible,
      },
      cancelToken: getOfferMaintenanceVisibilitySource.token,
    })
    .catch(handleErrorWithPromise());

export const createOfferMaintenanceEventSource = CancelToken.source();
export const createOfferMaintenanceEvent = ({ offerId, formData }) =>
  axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(
        `/Announcements/${offerId}/MaintenanceHistory/MaintenanceEvent`
      ),
      params: {
        v,
      },
      data: formData,
      cancelToken: createOfferMaintenanceEventSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferMaintenanceEventSource = CancelToken.source();
export const getOfferMaintenanceEvent = ({ offerId, maintenanceId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(
        `/Announcements/${offerId}/MaintenanceHistory/MaintenanceEvent/${maintenanceId}`
      ),
      params: {
        v,
      },
      cancelToken: getOfferMaintenanceEventSource.token,
    })
    .catch(handleErrorWithPromise());

export const saveOfferMaintenanceEventSource = CancelToken.source();
export const saveOfferMaintenanceEvent = ({
  offerId,
  maintenanceId,
  formData,
}) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(
        `/Announcements/${offerId}/MaintenanceHistory/MaintenanceEvent/${maintenanceId}`
      ),
      params: {
        v,
      },
      data: formData,
      cancelToken: saveOfferMaintenanceEventSource.token,
    })
    .catch(handleErrorWithPromise());

export const deleteOfferMaintenanceEventSource = CancelToken.source();
export const deleteOfferMaintenanceEvent = ({ offerId, maintenanceId }) =>
  axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(
        `/Announcements/${offerId}/MaintenanceHistory/MaintenanceEvent/${maintenanceId}`
      ),
      params: {
        v,
      },
      cancelToken: deleteOfferMaintenanceEventSource.token,
    })
    .catch(handleErrorWithPromise());

/**
 * Images:
 */

export const postImageToWaitingRoomSource = CancelToken.source();
export const postImageToWaitingRoom = ({ offerId, data }) =>
  axios
    .request({
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: arvalBackOfficeUrl(
        `/Announcements/${offerId}/ImagesWaitingRoom/Image`
      ),
      params: {
        v,
      },
      data,
      cancelToken: postImageToWaitingRoomSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferImagesSource = CancelToken.source();
export const getOfferImages = ({ offerId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Images`),
      params: {
        v,
      },
      cancelToken: getOfferImagesSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferImagesZippedLinkSource = CancelToken.source();
export const getOfferImagesZippedLink = ({ announcementId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Announcements/GetZippedAnnouncementImages'),
      params: {
        v,
        announcementId,
      },
      cancelToken: getOfferImagesZippedLinkSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferImagesTypeZipSource = CancelToken.source();
export const getOfferImagesTypeZip = ({ offerId, type }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Images/${type}`),
      responseType: 'blob',
      params: {
        v,
      },
      cancelToken: getOfferImagesTypeZipSource.token,
    })
    .catch(handleErrorWithPromise());

export const putOfferImagesSource = CancelToken.source();
export const putOfferImages = ({ offerId, formData }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Images`),
      params: {
        v,
      },
      data: formData,
      cancelToken: putOfferImagesSource.token,
    })
    .catch(handleErrorWithPromise());

export const postOfferImagesSource = CancelToken.source();
export const postOfferImages = ({ offerId, formData }) =>
  axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/Images`),
      params: {
        v,
      },
      data: formData,
      cancelToken: postOfferImagesSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferPortalsImagesSource = CancelToken.source();
export const getOfferPortalsImages = ({ offerId }) =>
  axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/PortalsImages`),
      params: {
        v,
      },
      cancelToken: getOfferPortalsImagesSource.token,
    })
    .catch(handleErrorWithPromise());

export const putOfferPortalsImagesSource = CancelToken.source();
export const putOfferPortalsImages = ({ offerId, formData }) =>
  axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/Announcements/${offerId}/PortalsImages`),
      params: {
        v,
      },
      data: formData,
      cancelToken: putOfferPortalsImagesSource.token,
    })
    .catch(handleErrorWithPromise());

export const getOfferExportsSource = CancelToken.source();
export const getOfferExports = ({ id }) => {
  return axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/AnnouncementExports'),
      params: {
        v,
        announcementId: id,
      },
      cancelToken: getOfferExportsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const postOfferExportsSource = CancelToken.source();
export const postOfferExports = ({
  portalId,
  announcementId,
  exportImages,
  releaseExportToPromote,
  saleExportToPromote,
}) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl('/AnnouncementExports'),
      params: {
        v,
      },
      data: {
        portalId,
        announcementId,
        exportImages,
        releaseExportToPromote,
        saleExportToPromote,
      },
      cancelToken: postOfferExportsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const putOfferExportsSource = CancelToken.source();
export const putOfferExports = ({
  announcementExportId,
  exportImages,
  releaseExportToPromote,
  saleExportToPromote,
}) => {
  return axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/AnnouncementExports/${announcementExportId}`),
      params: {
        v,
      },
      data: {
        exportImages,
        releaseExportToPromote,
        saleExportToPromote,
      },
      cancelToken: putOfferExportsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deleteOfferExportsSource = CancelToken.source();
export const deleteOfferExports = ({ announcementExportId }) => {
  return axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/AnnouncementExports/${announcementExportId}`),
      params: {
        v,
      },
      cancelToken: deleteOfferExportsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const publishOffersBulkSource = CancelToken.source();
export const publishOffersBulk = ({
  regNums,
  countryId,
  whetherToExportImages = false,
}) =>
  axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(`/AnnouncementExports/BulkPublications`),
      headers: {
        'Content-Type': 'text/json',
      },
      params: {
        v,
      },
      data: {
        registrationNumbers: regNums,
        whetherToExportImages,
        countryId,
      },
      cancelToken: publishOffersBulkSource.token,
    })
    .catch(handleErrorWithPromise());

export const getPublishOffersBulkStatusSource = CancelToken.source();
export const getPublishOffersBulkStatus = ({
  clearCacheEntry = false,
  countryId,
  pageNumber,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/AnnouncementExports/BulkPublications'),
      params: {
        v,
        countryId,
        pageNumber,
      },
      cancelToken: getPublishOffersBulkStatusSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getPageContentsSource = CancelToken.source();
export const getPageContents = ({
  portalId,
  tagType,
  pluralityType,
  orderBy,
  order,
  pageNumber,
  pageSize,
  purchaseOption,
}) => {
  return axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/PageContents/Portal/${portalId}`),
      params: {
        pageNumber: Number.parseInt(pageNumber, 10) || 1,
        pageSize: Number.parseInt(pageSize, 10) || DEFAULT_PAGE_SIZE,
        orderBy: orderBy || null,
        orderDirection: order || 'asc',
        v,
        tagType,
        pluralityType,
        purchaseOption,
      },
      cancelToken: getPageContentsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const createPageContentsDetailsSource = CancelToken.source();
export const createPageContentsDetails = ({ portalId, formData }) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(`/PageContents/Portal/${portalId}`),
      params: {
        v,
      },
      data: formData,
      cancelToken: createPageContentsDetailsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const getPageContentsDetailsSource = CancelToken.source();
export const getPageContentsDetails = ({ portalId, tagId }) => {
  return axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/PageContents/${tagId}/Portal/${portalId}`),
      params: {
        v,
      },
      cancelToken: getPageContentsDetailsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const savePageContentsDetailsSource = CancelToken.source();
export const savePageContentsDetails = ({ portalId, tagId, formData }) => {
  return axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/PageContents/${tagId}/Portal/${portalId}`),
      params: {
        v,
      },
      data: {
        ...formData,
      },
      cancelToken: savePageContentsDetailsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deletePageContentsDetailsSource = CancelToken.source();
export const deletePageContentsDetails = ({ portalId, tagId }) => {
  return axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/PageContents/${tagId}/Portal/${portalId}`),
      params: {
        v,
      },
      cancelToken: deletePageContentsDetailsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const getPortalAdditionalImagesSource = CancelToken.source();
export const getPortalAdditionalImages = ({ portalId, posId }) => {
  return axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/PortalsAdditionalImages`),
      params: {
        v,
        portalId,
        pointOfSaleId: posId,
      },
      cancelToken: getPortalAdditionalImagesSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const addPortalAdditionalImagesSource = CancelToken.source();
export const addPortalAdditionalImages = ({ formData }) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(`/PortalsAdditionalImages`),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        v,
      },
      data: formData,
      cancelToken: addPortalAdditionalImagesSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const setPortalAdditionalImagesSource = CancelToken.source();
export const setPortalAdditionalImages = ({ portalId, posId, imagesOrder }) => {
  return axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/PortalsAdditionalImages`),
      params: {
        v,
      },
      data: {
        portalId,
        pointOfSaleId: posId,
        imagesOrder,
      },
      cancelToken: setPortalAdditionalImagesSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deletePortalAdditionalImagesSource = CancelToken.source();
export const deletePortalAdditionalImages = ({ imageId }) => {
  return axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/PortalsAdditionalImages`),
      params: {
        v,
      },
      data: {
        portalAdditionalImageId: imageId,
      },
      cancelToken: deletePortalAdditionalImagesSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const getPortalImageWatermarkSource = CancelToken.source();
export const getPortalImageWatermark = ({ portalId, posId }) => {
  return axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/PortalsAdditionalImages/Watermark`),
      params: {
        v,
        portalId,
        pointOfSaleId: posId,
      },
      cancelToken: getPortalImageWatermarkSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const addPortalImageWatermarkSource = CancelToken.source();
export const addPortalImageWatermark = ({ formData }) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(`/PortalsAdditionalImages/Watermark`),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        v,
      },
      data: formData,
      cancelToken: addPortalImageWatermarkSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deletePortalImageWatermarkSource = CancelToken.source();
export const deletePortalImageWatermark = ({ imageId }) => {
  return axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/PortalsAdditionalImages/Watermark`),
      params: {
        v,
      },
      data: {
        portalWatermarkImageId: imageId,
      },
      cancelToken: deletePortalImageWatermarkSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const getPartnerLogoSource = CancelToken.source();
export const getPartnerLogo = ({ portalId, posId }) => {
  return axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/PartnersLogoImages`),
      params: {
        v,
        portalId,
        pointOfSaleId: posId,
      },
      cancelToken: getPartnerLogoSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const addPartnerLogoSource = CancelToken.source();
export const addPartnerLogo = ({ formData }) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(`/PartnersLogoImages`),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        v,
      },
      data: formData,
      cancelToken: addPartnerLogoSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deletePartnerLogoSource = CancelToken.source();
export const deletePartnerLogo = ({ imageId }) => {
  return axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/PartnersLogoImages`),
      params: {
        v,
      },
      data: {
        deletePartnerLogoImageId: imageId,
      },
      cancelToken: deletePartnerLogoSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const getPlayerAdditionalImagesSource = CancelToken.source();
export const getPlayerAdditionalImages = ({ portalId, posId }) => {
  return axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/PlayersAdditionalImages`),
      params: {
        v,
        portalId,
        pointOfSaleId: posId,
      },
      cancelToken: getPlayerAdditionalImagesSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const addPlayerAdditionalImagesSource = CancelToken.source();
export const addPlayerAdditionalImages = ({ formData }) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(`/PlayersAdditionalImages`),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        v,
      },
      data: formData,
      cancelToken: addPlayerAdditionalImagesSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const setPlayerAdditionalImagesSource = CancelToken.source();
export const setPlayerAdditionalImages = ({ portalId, posId, imagesOrder }) => {
  return axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl(`/PlayersAdditionalImages`),
      params: {
        v,
      },
      data: {
        portalId,
        pointOfSaleId: posId,
        imagesOrder,
      },
      cancelToken: setPlayerAdditionalImagesSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deletePlayerAdditionalImagesSource = CancelToken.source();
export const deletePlayerAdditionalImages = ({ imageId }) => {
  return axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/PlayersAdditionalImages`),
      params: {
        v,
      },
      data: {
        playerAdditionalImageId: imageId,
      },
      cancelToken: deletePlayerAdditionalImagesSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const getPlayerImageWatermarkSource = CancelToken.source();
export const getPlayerImageWatermark = ({ portalId, posId }) => {
  return axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/PlayersAdditionalImages/Watermark`),
      params: {
        v,
        portalId,
        pointOfSaleId: posId,
      },
      cancelToken: getPlayerImageWatermarkSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const addPlayerImageWatermarkSource = CancelToken.source();
export const addPlayerImageWatermark = ({ formData }) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl(`/PlayersAdditionalImages/Watermark`),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        v,
      },
      data: formData,
      cancelToken: addPlayerImageWatermarkSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deletePlayerImageWatermarkSource = CancelToken.source();
export const deletePlayerImageWatermark = ({ imageId }) => {
  return axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/PlayersAdditionalImages/Watermark`),
      params: {
        v,
      },
      data: {
        playerWatermarkImageId: imageId,
      },
      cancelToken: deletePlayerImageWatermarkSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const getPortalsForImagesSource = CancelToken.source();
export const getPortalsForImages = ({ posId }) => {
  return axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Portals`),
      params: {
        v,
        pointOfSaleId: posId,
      },
      cancelToken: getPortalsForImagesSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const getCountryGroupPortalsSource = CancelToken.source();
export const getCountryGroupPortals = ({ countryGroupId }) => {
  return axios
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Portals/CountryGroupsPortals`),
      params: {
        v,
        countryGroupId,
      },
      cancelToken: getCountryGroupPortalsSource.token,
    })
    .catch(handleErrorWithPromise());
};

/**
 * DICTIONARIES:
 */
export const getPurchaseOptionsSource = CancelToken.source();
export const getPurchaseOptions = ({ clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Dictionaries/PurchaseOptions'),
      params: {
        v,
      },
      cancelToken: getPurchaseOptionsSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getPromotionsPurchaseOptionsSource = CancelToken.source();
export const getPromotionsPurchaseOptions = ({
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Dictionaries/PromotionsPurchaseOptions'),
      params: {
        v,
      },
      cancelToken: getPromotionsPurchaseOptionsSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getPromotionTypesSource = CancelToken.source();
export const getPromotionTypes = ({ clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Dictionaries/PromotionTypes'),
      params: {
        v,
      },
      cancelToken: getPromotionTypesSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getEquipmentItemCategoriesSource = CancelToken.source();
export const getEquipmentItemCategories = ({
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Dictionaries/EquipmentItemCategory'),
      params: {
        v,
      },
      cancelToken: getEquipmentItemCategoriesSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getDictionariesLabelsSource = CancelToken.source();
export const getDictionariesLabels = ({
  countryGroupId,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(
        `/Dictionaries/${countryGroupId}/MarketingLabels`
      ),
      params: {
        v,
      },
      cancelToken: getDictionariesLabelsSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getPortalsSource = CancelToken.source();
export const getPortals = ({ pointOfSaleId, clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/PageContents'),
      params: {
        v,
        pointOfSaleId,
      },
      cancelToken: getPortalsSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getTagTypesSource = CancelToken.source();
export const getTagTypes = ({ portalId, clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/PageContents/Portal/${portalId}/TagTypes`),
      params: {
        v,
      },
      cancelToken: getTagTypesSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getPluralityTypesSource = CancelToken.source();
export const getPluralityTypes = ({
  portalId,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(
        `/PageContents/Portal/${portalId}/PluralityTypes`
      ),
      params: {
        v,
      },
      cancelToken: getPluralityTypesSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getExternalPromotionsSource = CancelToken.source();
export const getExternalPromotions = (portalId, externalId) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/ExternalApi/GetPromotions'),
      params: {
        v,
        portalId,
        externalId,
      },
      cancelToken: getExternalPromotionsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const setExternalPromotionsSource = CancelToken.source();
export const setExternalPromotions = (data) => {
  return axiosWithCache
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl('/ExternalApi/SetPromotions'),
      params: {
        v,
      },
      data,
      cancelToken: setExternalPromotionsSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const getTransactionsPortalsSource = CancelToken.source();
export const getTransactionsPortals = ({
  pointOfSaleId,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Transactions'),
      params: {
        v,
        pointOfSaleId,
      },
      cancelToken: getTransactionsPortalsSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getTransactionsSource = CancelToken.source();
export const getTransactions = ({
  portalId,
  purchaseOption,
  pageNumber,
  orderBy,
  order,
  pageSize,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Transactions/Portal/${portalId}`),
      params: {
        v,
        purchaseOption,
        pageNumber: Number.parseInt(pageNumber, 10) || 1,
        pageSize: Number.parseInt(pageSize, 10) || DEFAULT_PAGE_SIZE,
        orderBy: orderBy || null,
        orderDirection: order || 'asc',
      },
      cancelToken: getTransactionsSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getTransactionsPurchaseOptionsSource = CancelToken.source();
export const getTransactionsPurchaseOptions = ({
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Transactions/PurchaseOptions'),
      params: {
        v,
      },
      cancelToken: getTransactionsPurchaseOptionsSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const importMatrixExcelFileSource = CancelToken.source();
export const importMatrixExcelFile = ({
  clearCacheEntry = false,
  data,
} = {}) => {
  return axios
    .request({
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: arvalBackOfficeUrl('/MatrixRelease/ImportExcelFile'),
      params: {
        v,
      },
      data,
      cancelToken: importMatrixExcelFileSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getMatrixExcelFileSource = CancelToken.source();
export const getMatrixExcelFile = ({
  clearCacheEntry = false,
  countryId,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/MatrixRelease'),
      params: {
        v,
        countryId,
      },
      responseType: 'blob',
      cancelToken: getMatrixExcelFileSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getMatrixExcelFileInfoSource = CancelToken.source();
export const getMatrixExcelFileInfo = ({
  clearCacheEntry = false,
  countryId,
  pageNumber,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/MatrixRelease/FileInfo'),
      params: {
        v,
        countryId,
        pageNumber,
      },
      cancelToken: getMatrixExcelFileInfoSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getLabelsSource = CancelToken.source();
export const getLabels = ({
  countryGroupId,
  orderBy,
  order,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/MarketingLabels'),
      params: {
        v,
        countryGroupId,
        orderBy,
        orderDirection: order || 'asc',
      },
      cancelToken: getLabelsSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getLabelSource = CancelToken.source();
export const getLabel = ({ id, clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/MarketingLabels/${id}`),
      params: {
        v,
      },
      cancelToken: getLabelSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const createLabelSource = CancelToken.source();
export const createLabel = ({
  countryGroupId,
  code,
  color,
  translations,
} = {}) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl('/MarketingLabels'),
      params: {
        v,
      },
      data: {
        countryGroupId,
        code,
        color,
        translations,
      },
      cancelToken: createLabelSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const editLabelSource = CancelToken.source();
export const editLabel = ({ id, code, color, translations } = {}) => {
  return axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl('/MarketingLabels'),
      params: {
        v,
      },
      data: {
        id,
        code,
        color,
        translations,
      },
      cancelToken: editLabelSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deleteLabelSource = CancelToken.source();
export const deleteLabel = ({ labelId }) =>
  axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/MarketingLabels/${labelId}`),
      params: {
        v,
      },
      data: {},
      cancelToken: deleteLabelSource.token,
    })
    .catch(handleErrorWithPromise());

export const getCountryGroupLangSource = CancelToken.source();
export const getCountryGroupLang = ({
  countryGroupId,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/MarketingLabels/Languages'),
      params: {
        v,
        countryGroupId,
      },
      cancelToken: getCountryGroupLangSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getPartnerCompaniesSource = CancelToken.source();
export const getPartnerCompanies = ({
  countryGroupId,
  orderBy,
  order,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Companies`),
      params: {
        v,
        countryGroupId,
        orderBy,
        orderDirection: order || 'asc',
      },
      cancelToken: getPartnerCompaniesSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getPartnerCompaniesGroupsCodesSource = CancelToken.source();
export const getPartnerCompaniesGroupsCodes = ({
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/CompanyGroups/Codes'),
      params: {
        v,
      },
      cancelToken: getPartnerCompaniesGroupsCodesSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getPartnerCompanySource = CancelToken.source();
export const getPartnerCompany = ({ id, clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Companies/${id}`),
      params: {
        v,
      },
      cancelToken: getPartnerCompanySource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const createPartnerCompanySource = CancelToken.source();
export const createPartnerCompany = ({
  countryGroupId,
  name,
  domains,
  groupCode,
} = {}) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl('/Companies'),
      params: {
        v,
      },
      data: {
        countryGroupId,
        name,
        domains,
        groupCode,
      },
      cancelToken: createPartnerCompanySource.token,
    })
    .catch(handleErrorWithPromise());
};

export const editPartnerCompanySource = CancelToken.source();
export const editPartnerCompany = ({
  id,
  countryGroupId,
  name,
  domains,
  groupCode,
} = {}) => {
  return axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl('/Companies'),
      params: {
        v,
      },
      data: {
        id,
        countryGroupId,
        name,
        domains,
        groupCode,
      },
      cancelToken: editPartnerCompanySource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deletePartnerCompanySource = CancelToken.source();
export const deletePartnerCompany = ({ id }) =>
  axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/Companies/${id}`),
      params: {
        v,
      },
      data: {},
      cancelToken: deletePartnerCompanySource.token,
    })
    .catch(handleErrorWithPromise());

export const getVehicleGroupsSource = CancelToken.source();
export const getVehicleGroups = ({
  countryGroupId,
  orderBy,
  order,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/VehicleGroups'),
      params: {
        v,
        countryGroupId,
        orderBy,
        orderDirection: order || 'asc',
      },
      cancelToken: getVehicleGroupsSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getVehicleGroupSource = CancelToken.source();
export const getVehicleGroup = ({ id, clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/VehicleGroups/${id}`),
      params: {
        v,
      },
      cancelToken: getVehicleGroupSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const createVehicleGroupSource = CancelToken.source();
export const createVehicleGroup = ({
  countryGroupId,
  name,
  campaignKey,
  priority = 0,
  marketingLabel,
  filters,
} = {}) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl('/VehicleGroups'),
      params: {
        v,
      },
      data: {
        countryGroupId,
        name,
        campaignKey,
        priority,
        marketingLabel,
        filters,
      },
      cancelToken: createVehicleGroupSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const editVehicleGroupSource = CancelToken.source();
export const editVehicleGroup = ({
  id,
  countryGroupId,
  name,
  campaignKey,
  priority,
  marketingLabel,
  filters,
} = {}) => {
  return axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl('/VehicleGroups'),
      params: {
        v,
      },
      data: {
        id,
        countryGroupId,
        name,
        campaignKey,
        priority,
        marketingLabel,
        filters,
      },
      cancelToken: editVehicleGroupSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deleteVehicleGroupSource = CancelToken.source();
export const deleteVehicleGroup = ({ id }) =>
  axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/VehicleGroups/${id}`),
      params: {
        v,
      },
      data: {},
      cancelToken: deleteVehicleGroupSource.token,
    })
    .catch(handleErrorWithPromise());

export const getPromotionParametersSource = CancelToken.source();
export const getPromotionParameters = ({
  countryGroupId,
  orderBy,
  order,
  clearCacheEntry = false,
} = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl('/Promotions'),
      params: {
        v,
        countryGroupId,
        orderBy,
        orderDirection: order || 'asc',
      },
      cancelToken: getPromotionParametersSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const getPromotionParameterSource = CancelToken.source();
export const getPromotionParameter = ({ id, clearCacheEntry = false } = {}) => {
  return axiosWithCache
    .request({
      method: 'GET',
      url: arvalBackOfficeUrl(`/Promotions/${id}`),
      params: {
        v,
      },
      cancelToken: getPromotionParameterSource.token,
      clearCacheEntry,
    })
    .catch(handleErrorWithPromise());
};

export const createPromotionParametersSource = CancelToken.source();
export const createPromotionParameters = ({
  countryGroupId,
  companyGroups,
  name,
  purchaseOption,
  promotionType,
  promotionValue,
  startDate,
  endDate,
  promoCode,
  tooltip,
  vehicleGroupId,
  portals,
} = {}) => {
  return axios
    .request({
      method: 'POST',
      url: arvalBackOfficeUrl('/Promotions'),
      params: {
        v,
      },
      data: {
        countryGroupId,
        companyGroups,
        name,
        purchaseOption,
        promotionType,
        promotionValue,
        startDate,
        endDate,
        promoCode,
        tooltip,
        vehicleGroupId,
        portals,
      },
      cancelToken: createPromotionParametersSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const editPromotionParametersSource = CancelToken.source();
export const editPromotionParameters = ({
  id,
  countryGroupId,
  companyGroups,
  name,
  purchaseOption,
  promotionType,
  promotionValue,
  startDate,
  endDate,
  promoCode,
  tooltip,
  vehicleGroupId,
  portals,
} = {}) => {
  return axios
    .request({
      method: 'PUT',
      url: arvalBackOfficeUrl('/Promotions'),
      params: {
        v,
      },
      data: {
        id,
        countryGroupId,
        companyGroups,
        name,
        purchaseOption,
        promotionType,
        promotionValue,
        startDate,
        endDate,
        promoCode,
        tooltip,
        vehicleGroupId,
        portals,
      },
      cancelToken: editPromotionParametersSource.token,
    })
    .catch(handleErrorWithPromise());
};

export const deletePromotionParametersSource = CancelToken.source();
export const deletePromotionParameters = ({ id }) =>
  axios
    .request({
      method: 'DELETE',
      url: arvalBackOfficeUrl(`/Promotions/${id}`),
      params: {
        v,
      },
      data: {},
      cancelToken: deletePromotionParametersSource.token,
    })
    .catch(handleErrorWithPromise());
