import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import split from 'lodash/split';
import map from 'lodash/map';
import { initReactI18next } from 'react-i18next';
import { toast } from 'react-toastify';

import { LOCALES, I18N_LS_KEY } from './app/core/constants';
import { path, randomId } from './app/core/utils';

if (!localStorage[I18N_LS_KEY]) {
  // Entry point of default language.
  localStorage[I18N_LS_KEY] =
    split(navigator.language, '-')[0] || LOCALES['en-GB'].lang;
}

i18next
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: LOCALES['en-GB'].lang,
    debug: process.env.NODE_ENV !== 'production',
    lng: localStorage[I18N_LS_KEY],
    whitelist: map(LOCALES, (locale) => locale.lang),

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: path(`/locales/{{lng}}/{{ns}}.json?version=${randomId()}`),
    },
  })
  .catch((error) => {
    toast.error(error.message);
  });

export const i18n = i18next;
