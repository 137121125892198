import * as actionsWithTypes from '../actions/all';

const initialState = {
  isBlockedUnload: false,
  showPrompt: false,
};

// this reducer is synced with localstorage
// it should contain only save-safe data, preferably tokens only
export function blockUnloadReducer(state = initialState, action) {
  switch (action.type) {
    case actionsWithTypes.TOGGLE_BLOCK_UNLOAD: {
      return {
        ...initialState,
        isBlockedUnload: action.payload === true,
        showPrompt: action.payload === false ? false : state.showPrompt,
      };
    }

    case actionsWithTypes.PROMPT_TOGGLE_BLOCK_UNLOAD: {
      return {
        showPrompt: action.payload === true,
      };
    }

    default:
      return state;
  }
}
