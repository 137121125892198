import React from 'react';
import { path } from '../../core/utils';

export const PortalHeaderSmall = (props) => {
  if (props.isLoggedIn) {
    return null;
  }

  return (
    <header className='main-header popup-hidde'>
      <div className='main-container'>
        <div className='container header-content header-content-small l-min-width'>
          <div className='row'>
            <div className='col-xs-12 text-right'>
              <div className='new-logo'>
                <img
                  className=''
                  src={path('/assets/images/logo-arval.png')}
                  alt='Arval'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
